.header {
  background-color: var(--primaryColor);

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  height: 6vh;
}

.header__content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__content ul {
  display: flex;
}

.header__content ul li {
  margin: 0 15px;

  color: var(--whiteColor);
}
