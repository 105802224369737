:root {
  --backgroundColor: #fefdfd;

  --textColor: #23272f;

  --primaryColor: #3765c3;
  --secondaryColor: #c33737;
  --disableColor : #999999;
  --whiteColor: #fff;
  --blackColor: #23272f;

  --shadowBorder: #e7e7e9;
}
