.workspace__page {
  display: grid;
  align-items: center;
  justify-content: center;
  grid: auto-flow / repeat(10,10.25%);
  padding: 1.5%;
}

.droppable__div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 99%;
  Height: 900px;
}

.draggable__div img {
  padding-top: 5px;
  padding-left: 5px;
  padding-bottom: 5px;

  padding-right: 5px;
  margin-top: 10px;
  margin-bottom: -143px;
}
