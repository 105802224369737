.footer {
  background-color: var(--primaryColor);
  color: var(--whiteColor);

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  height: 4vh;
}

@media screen and (max-width: 600px) {
  .footer {
    font-size: 14px;
  }
}

@media screen and (max-width: 375px) {
  .footer {
    font-size: 12px;
  }
}
