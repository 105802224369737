html,
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: var(--backgroundColor);
  line-height: 1.2;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
