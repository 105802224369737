.page__content {
  padding: 1%;
  height: 90vh;
}

.create__content {
  display: flex;
}

.blockly__bloc {
  box-shadow: 0 4px 2px -2px var(--shadowBorder);
  border: 1.5px solid var(--shadowBorder);
  background-color: var(--whiteColor);
  border-radius: 5px;
  margin-right: 8px;
  height: 78vh;
  width: 50%;
}

.create__design {
  box-shadow: 0 4px 2px -2px var(--shadowBorder);
  border: 1.5px solid var(--shadowBorder);
  background-color: var(--whiteColor);
  border-radius: 5px;
  margin-left: 8px;
  height: 78vh;
  width: 50%;

  overflow-y: scroll;
  scrollbar-color: var(--shadowBorder);
  scrollbar-width: thin;
}

.btn__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  margin: 20px;
}

.btn {
  font-size: 16px;
  height: 35px;
  width: 6%;
}

.btn__primary {
  background-color: var(--primaryColor);
  color: var(--whiteColor);
  border-radius: 5px;
  border: none;
}

.btn__secondary {
  border: 2px solid var(--primaryColor);
  background-color: var(--whiteColor);
  color: var(--primaryColor);

  border-radius: 5px;

  margin-left: 2%;
}

.btn__tertiary {
  border: 2px solid var(--secondaryColor);
  background-color: var(--whiteColor);
  color: var(--secondaryColor);
  border-radius: 5px;
  margin-left: 2%;
}

.btn__disabled {
  border: 2px solid var(--disableColor);
  background-color: var(--whiteColor);
  color: var(--disableColor);
  border-radius: 5px;
  margin-left: 2%;
  pointer-events: none;
}

.btn__message {
  margin-left: 20px;
}

@media screen and (max-width: 1024px) {
  .page__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }

  .create__content,
  .btn__container {
    display: none;
  }

  .mobileMessage {
    color: var(--primaryColor);
    font-size: 1.2rem;
    padding: 20px;
  }
}
